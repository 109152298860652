'use client';

import React, {
    createContext,
    FunctionComponent,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { AppContext } from '../AppContext';
import { useIam } from '../../services/IAm/hooks';
import { JestMock, Analytics, IAnalytics } from '@shared/analytics';
import { usePostHog } from 'posthog-js/react';

const BASE_URL = process.env.NEXT_PUBLIC_CADEMY_API_URL as string;

class InitialAnalytics implements IAnalytics {
    record = {
        educatorProfileView: () => Promise.resolve(),
        coursePageView: () => Promise.resolve(),
        pageView: () => Promise.resolve(),
        socialLinkRedirect: () => Promise.resolve(),
        externalRedirect: () => Promise.resolve(),
        event: () => Promise.resolve(),
        identifyUser: () => Promise.resolve(),
        educatorCreated: () => Promise.resolve(),
    };
}

export type AnalyticsContextShape = {
    analytics: IAnalytics;
};

export const AnalyticsContext = createContext<AnalyticsContextShape>({
    analytics: new InitialAnalytics(),
});

export const AnalyticsContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const userId = useRef<string | undefined>();
    const appContext = useContext(AppContext);
    const recordAgainst = useMemo(() => {
        switch (appContext.context) {
            case 'marketplace':
                return 'marketplace';
            case 'minisite':
                return 'minisite';
            case 'embed':
                return 'embed';
        }
    }, [appContext]);
    const posthog = usePostHog();

    const iam = useIam();

    const analytics = useMemo(() => {
        if (process.env.NODE_ENV === 'test') {
            return new JestMock();
        }

        return new Analytics({
            baseUrl: new URL(BASE_URL),
            segmentApiKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY,
            recordAgainst: recordAgainst,
        });
    }, [recordAgainst]);

    const context = useMemo<AnalyticsContextShape>(() => {
        return {
            analytics,
        };
    }, [analytics]);

    useEffect(() => {
        if (iam.iamReady && iam.iam.authenticated === true && userId.current !== iam.iam.userId) {
            analytics.record.identifyUser(iam.iam);
            posthog.identify(iam.iam.userId, {
                firstName: iam.iam.firstName,
                lastName: iam.iam.lastName,
                email: iam.iam.email,
                isAdmin: iam.iam.educators.length > 0,
            });
            userId.current = iam.iam.userId;
        }
    }, [iam, analytics, posthog]);

    return <AnalyticsContext.Provider value={context}>{children}</AnalyticsContext.Provider>;
};
