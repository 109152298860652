'use client';
import { CademyError } from '@shared/domain-shared';
import { IAm } from '@shared/types';
import {
    FunctionComponent,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { IAmContext, IAMContextState } from '../../context';
import IAMStore from '../..';

export const IAmClientProvider: FunctionComponent<PropsWithChildren<{ iam?: IAm }>> = ({
    iam: initialIAm,
    children,
}) => {
    const [iam, setIAm] = useState<IAm>(initialIAm || IAMStore.iam);
    const [error, setError] = useState<CademyError | null>(null);
    const [isReady, setIsReady] = useState(Boolean(initialIAm) || IAMStore.isReady);

    useEffect(() => {
        if (initialIAm) {
            IAMStore.setIAm(initialIAm);
        } else {
            setIAm(IAMStore.iam);
            setIsReady(IAMStore.isReady);
        }
    }, [initialIAm]);

    useEffect(() => {
        const onChange = (iam: IAm) => {
            setIAm(iam);
        };
        IAMStore.events.on('change', onChange);
        return () => {
            IAMStore.events.removeListener('change', onChange);
        };
    }, []);

    useEffect(() => {
        const onReady = (iam: IAm) => {
            setIAm(iam);
            setIsReady(true);
        };
        IAMStore.events.on('ready', onReady);
        return () => {
            IAMStore.events.removeListener('ready', onReady);
        };
    }, []);

    useEffect(() => {
        const onError = (error: CademyError) => {
            setError(error);
        };
        IAMStore.events.on('error', onError);
        return () => {
            IAMStore.events.removeListener('error', onError);
        };
    }, []);

    const refresh = useCallback(() => {
        return IAMStore.refresh();
    }, []);

    const setStoreIAm = useCallback((iam: IAm) => {
        return IAMStore.setIAm(iam);
    }, []);

    useEffect(() => {
        if (isReady !== true) {
            refresh();
        }
    }, [refresh, isReady]);

    const value = useMemo((): IAMContextState => {
        return {
            iam: iam,
            isReady,
            error,
            refresh,
            setIAm: setStoreIAm,
        };
    }, [iam, isReady, error, refresh, setStoreIAm]);

    return <IAmContext.Provider value={value}>{children}</IAmContext.Provider>;
};
