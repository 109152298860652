"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsError = void 0;
const domain_shared_1 = require("@shared/domain-shared");
class AnalyticsError extends domain_shared_1.CademyError {
    constructor(localType, title, detail, status, instance) {
        const type = `analytics/${localType}`;
        super(type, title, detail, status, instance);
    }
}
exports.AnalyticsError = AnalyticsError;
exports.default = AnalyticsError;
