import { CademyError } from '@shared/domain-shared';
import { IAm } from '@shared/types';

const getCookieHeader = (cookies?: string | { [key: string]: any }): { Cookie: string } | {} => {
    if (!cookies) {
        return {};
    }
    if (typeof cookies === 'string') {
        return { Cookie: cookies };
    }
    return {
        Cookie: Object.entries(cookies).reduce((str, [key, value]) => {
            return `${str}${key}=${value}; `;
        }, ''),
    };
};

export const getIAm = async (cookies?: string | { [key: string]: any }): Promise<IAm> => {
    const cookiesHeader = getCookieHeader(cookies);
    return fetch(`${process.env.NEXT_PUBLIC_CADEMY_API_URL}/users/iam`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            ...cookiesHeader,
        },
    })
        .then(async (response) => {
            if (response.ok !== true) {
                if (response.headers.get('content-type') === 'application/problem+json') {
                    const problemDetails = await response.json();
                    const error = CademyError.fromObject(problemDetails);
                    throw error;
                }
                const error = await response.text();
                throw new Error(error);
            }

            return response.json();
        })
        .catch((error) => {
            console.error(error);
            return {
                authenticated: false,
            };
        });
};
