"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Path = void 0;
class Path {
    constructor(path) {
        const pathWithNoTrailingSlash = path.endsWith('/') ? path.slice(0, -1) : path;
        const pathWithLeadingSlash = pathWithNoTrailingSlash.startsWith('/')
            ? pathWithNoTrailingSlash
            : '/' + pathWithNoTrailingSlash;
        this.path = pathWithLeadingSlash;
    }
    /**
     * @example '/path/to/resource'
     */
    get value() {
        return this.path;
    }
    /**
     * @example 'path/to/resource'
     */
    get withoutLeadingSlash() {
        return this.path.slice(1, this.path.length);
    }
}
exports.Path = Path;
exports.default = Path;
