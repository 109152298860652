import '../app/global.scss';
import { AppProps } from 'next/app';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import CustomRecordPageView from '../types/CustomRecordPageView';
import { PagesProgressBar } from 'next-nprogress-bar';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useRouter } from 'next/router';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import { IAmClientProvider } from '../services/IAm/provider/clientProvider';
import { useCaptureUtms } from '../hooks/useUtms';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { AnalyticsContextProvider } from '../contexts/AnalyticsContext';
import { LegacyPageViewAnalytics } from '../pages_components/LegacyPageViewAnalytics';

type Props = AppProps & {
    Component: AppProps['Component'] & Partial<CustomRecordPageView>;
};

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.posthog.com',
        session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
                password: true,
            },
        },
        // Enable debug mode in development
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.debug();
        },
    });
}

export default function MyApp({ Component, pageProps }: Props) {
    const [queryClient] = useState(() => new QueryClient());
    const router = useRouter();
    useCaptureUtms();

    useEffect(() => {
        // Track page views
        const handleRouteChange = () => posthog?.capture('$pageview');
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);

    return (
        <>
            <PostHogProvider client={posthog}>
                <QueryClientProvider client={queryClient}>
                    <Hydrate state={pageProps.dehydratedState}>
                        <IAmClientProvider iam={pageProps.initialIAm}>
                            <AnalyticsContextProvider>
                                <LegacyPageViewAnalytics Component={Component} />
                                <PagesProgressBar color="#5c85ff" height="3px" />
                                <Component {...pageProps} />
                                <VercelAnalytics />
                            </AnalyticsContextProvider>
                        </IAmClientProvider>
                    </Hydrate>
                </QueryClientProvider>
            </PostHogProvider>
            <SpeedInsights />
        </>
    );
}
