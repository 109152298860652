'use client';
import { useContext } from 'react';
import { IAmContext } from '../context';

export const useIam = () => {
    const iamState = useContext(IAmContext);
    return { iam: iamState.iam, iamReady: iamState.isReady };
};

export const useRefreshIam = () => {
    const iamState = useContext(IAmContext);
    return iamState.refresh;
};

export const useSetIam = () => {
    const iamState = useContext(IAmContext);
    return iamState.setIAm;
};

export const useAuthenticatedIam = () => {
    const iamState = useContext(IAmContext);

    if (iamState.iam?.authenticated !== true) {
        throw new Error('No authenticated IAM found');
    }

    return { iam: iamState.iam, iamReady: iamState.isReady };
};
