"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @see https://www.30secondsofcode.org/js/s/to-title-case
 */
const toTitleCase = (value) => {
    const parts = value.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
    if (!parts) {
        return '';
    }
    return parts.map((x) => x.charAt(0).toUpperCase() + x.slice(1)).join(' ');
};
exports.default = toTitleCase;
