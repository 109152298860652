import React, { FunctionComponent } from 'react';
import { AppProps } from 'next/app';
import CustomRecordPageView from '../../types/CustomRecordPageView';
import useLegacyPageViewAnalytics from '../../hooks/useLegacyPageViewAnalytics';

export type LegacyAnalyticsProps = {
    Component: AppProps['Component'] & Partial<CustomRecordPageView>;
};

/**
 * @deprecated superseded by app directory usePageViewAnalytics
 */
export const LegacyPageViewAnalytics: FunctionComponent<LegacyAnalyticsProps> = ({ Component }) => {
    useLegacyPageViewAnalytics(async (analytics, pathname, url) => {
        if (Component.customRecordPageView) {
            return;
        }
        await analytics.record.pageView(pathname, url);
    });

    return null;
};
