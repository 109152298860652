'use client';
import { IAm } from '@shared/types';
import EventEmitter from 'events';
import { getIAm } from './getIAm';
import { CademyError } from '@shared/domain-shared';

interface IIAMStore {
    get iam(): IAm;
    get isReady(): boolean;
    setIAm(iam: IAm): void;
    refresh(): Promise<IAm>;
    events: {
        on(event: 'change', callback: (iam: IAm) => any): void;
        on(event: 'ready', callback: (iam: IAm) => any): void;
        on(event: 'error', callback: (error: CademyError) => any): void;

        removeListener(event: 'change', callback: (iam: IAm) => any): void;
        removeListener(event: 'ready', callback: (iam: IAm) => any): void;
        removeListener(event: 'error', callback: (error: CademyError) => any): void;
    };
}

export const DEFAULT_IAM: IAm = {
    authenticated: false,
};

const emitter = new EventEmitter();

const state: {
    iam: IAm;
    isReady: boolean;
    iamFetch: Promise<IAm> | null;
} = {
    iam: DEFAULT_IAM,
    isReady: false,
    iamFetch: null,
};

const isSameIAm = (compare: IAm): boolean => {
    if (state.iam.authenticated === false && compare.authenticated === false) {
        return true;
    }
    if (state.iam.authenticated === true && compare.authenticated === true) {
        return (
            state.iam.id === compare.id &&
            state.iam.email === compare.email &&
            state.iam.firstName === compare.firstName &&
            state.iam.lastName === compare.lastName
        );
    }
    return state.iam.authenticated === compare.authenticated;
};

const setIsReady = () => {
    if (state.isReady === true) {
        return;
    }
    state.isReady = true;
    emitter.emit('ready', state.iam);
};

const setIAm = (iam: IAm) => {
    if (isSameIAm(iam) === false) {
        state.iam = iam;
        emitter.emit('change', iam);
    }
    setIsReady();
};

const refresh = (): Promise<IAm> => {
    if (state.iamFetch) {
        return state.iamFetch;
    }
    state.iamFetch = getIAm()
        .then((iam) => {
            setIAm(iam);
            state.iamFetch = null;
            return iam;
        })
        .catch((error) => {
            emitter.emit('error', CademyError.fromUnknown(error));
            return DEFAULT_IAM;
        });
    return state.iamFetch;
};

/**
 * We need to make sure to run a mock implementation on the server so we don't potentially store and
 * use tokens between client requests.
 */
const serverMockStore: IIAMStore = {
    get iam() {
        return state.iam;
    },
    get isReady() {
        return false;
    },
    setIAm() {},
    refresh() {
        return Promise.resolve(DEFAULT_IAM);
    },
    events: {
        on: () => {},
        removeListener: () => {},
    },
};

const clientStore: IIAMStore = {
    get iam() {
        return state.iam;
    },
    get isReady() {
        return state.isReady;
    },
    setIAm,
    refresh,
    events: emitter,
};

export const IAMStore = typeof window !== 'undefined' ? clientStore : serverMockStore;

export default IAMStore;
