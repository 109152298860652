"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicString = void 0;
class BasicString {
    constructor(value) {
        this.value = value;
        //
    }
}
exports.BasicString = BasicString;
