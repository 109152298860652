"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkTypes = void 0;
exports.linkTypes = [
    'facebook',
    'instagram',
    'linkedin',
    'pinterest',
    'twitter',
    'web',
    'youtube',
    'tel',
];
