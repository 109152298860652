"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JestMock = void 0;
class JestMock {
    constructor() {
        this.record = {
            educatorProfileView: jest.fn().mockResolvedValue(Promise.resolve()),
            coursePageView: jest.fn().mockResolvedValue(Promise.resolve()),
            pageView: jest.fn().mockResolvedValue(Promise.resolve()),
            socialLinkRedirect: jest.fn().mockResolvedValue(Promise.resolve()),
            externalRedirect: jest.fn().mockResolvedValue(Promise.resolve()),
            event: jest.fn().mockResolvedValue(Promise.resolve()),
            identifyUser: jest.fn().mockResolvedValue(Promise.resolve()),
            educatorCreated: jest.fn().mockResolvedValue(Promise.resolve()),
        };
    }
}
exports.JestMock = JestMock;
exports.default = JestMock;
