import { CademyError } from '@shared/domain-shared';
import { IAm } from '@shared/types';
import { createContext } from 'react';
import IAMStore, { DEFAULT_IAM } from '..';

export type IAMContextState = {
    isReady: boolean;
    error: CademyError | null;
    iam: IAm;
    refresh: (iam?: IAm) => Promise<IAm>;
    setIAm: (iam: IAm) => void;
};

export const IAmContext = createContext<IAMContextState>({
    isReady: false,
    error: null,
    iam: DEFAULT_IAM,
    refresh: IAMStore.refresh,
    setIAm: IAMStore.setIAm,
});
