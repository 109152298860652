"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Identity = void 0;
const ulid_1 = require("ulid");
class Identity {
    constructor(value) {
        this.value = value;
        //
    }
    static generate() {
        return new this((0, ulid_1.ulid)());
    }
    toString() {
        return this.value;
    }
}
exports.Identity = Identity;
exports.default = Identity;
