"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const NO_CONTEXT = {
    type: 'none',
};
class EventRecorder {
    constructor(destinations) {
        this.destinations = destinations;
        //
    }
    educatorProfileView(educatorId, name, url, recordedAt) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this.destinations.map((destination) => __awaiter(this, void 0, void 0, function* () {
                return destination.educatorProfileView(educatorId, name, url, recordedAt);
            })));
        });
    }
    coursePageView(educatorId, courseId, name, url, recordedAt) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this.destinations.map((destination) => __awaiter(this, void 0, void 0, function* () {
                return destination.coursePageView(educatorId, courseId, name, url, recordedAt);
            })));
        });
    }
    pageView(name, url, context, recordedAt) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this.destinations.map((destination) => __awaiter(this, void 0, void 0, function* () {
                return destination.pageView(name, url, context, recordedAt);
            })));
        });
    }
    socialLinkRedirect(href, linkType, name, url, educatorId, recordedAt) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this.destinations.map((destination) => __awaiter(this, void 0, void 0, function* () {
                return destination.socialLinkRedirect(href, linkType, name, url, educatorId, recordedAt);
            })));
        });
    }
    externalRedirect(href, source, context = NO_CONTEXT, recordedAt) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this.destinations.map((destination) => __awaiter(this, void 0, void 0, function* () {
                return destination.externalRedirect(href, source, context, recordedAt);
            })));
        });
    }
    event(name, context, recordedAt) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this.destinations.map((destination) => __awaiter(this, void 0, void 0, function* () {
                return destination.event(name, context, recordedAt);
            })));
        });
    }
    identifyUser(user) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this.destinations.map((destination) => __awaiter(this, void 0, void 0, function* () {
                return destination.identifyUser(user);
            })));
        });
    }
    educatorCreated(createdBy, educatorId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this.destinations.map((destination) => __awaiter(this, void 0, void 0, function* () {
                return destination.educatorCreated(createdBy, educatorId);
            })));
        });
    }
}
exports.default = EventRecorder;
