import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import { IAnalytics } from '@shared/analytics';

export type PageViewCallback = (analytics: IAnalytics, pathname: string, url: URL) => Promise<void>;

export type Options = {
    pageLoaded?: boolean;
};

const defaultOptions: Options = {
    pageLoaded: true,
};

/**
 *
 * @deprecated superseded by app directory usePageViewAnalytics
 */
const useLegacyPageViewAnalytics = (
    callback: PageViewCallback,
    { pageLoaded }: Options = defaultOptions
) => {
    const { analytics } = useContext(AnalyticsContext);
    const router = useRouter();
    const [hasRunOnCurrentPage, setHasRunOnCurrentPage] = useState<boolean>(false);

    const url = typeof window !== 'undefined' ? window.location.href : undefined;

    useEffect(() => {
        setHasRunOnCurrentPage(false);
    }, [url]);

    const pageViewCallback = useCallback<() => Promise<void>>(async () => {
        if (!url) {
            return;
        }

        callback(analytics, router.pathname, new URL(url));
    }, [router, callback, url, analytics]);

    const shouldRunCallback = useMemo(() => {
        if (!url) {
            return false;
        }

        if (pageLoaded === false) {
            return false;
        }

        if (hasRunOnCurrentPage === true) {
            return false;
        }

        return true;
    }, [url, pageLoaded, hasRunOnCurrentPage]);

    useEffect(() => {
        if (shouldRunCallback === false) {
            return;
        }

        setHasRunOnCurrentPage(true);

        pageViewCallback();
    }, [shouldRunCallback, pageViewCallback]);
};

export default useLegacyPageViewAnalytics;
