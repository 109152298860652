'use client';
import { SupportedUtms, UTMs } from '@shared/types';
import { useCallback, useEffect } from 'react';

export const PARAMS_TO_CAPTURE: SupportedUtms[] = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'utm_id',
    'referrer',
    'rfrl',
    'trk',
];

const filterParams = (searchParams: URLSearchParams | Record<string, string>): UTMs => {
    return PARAMS_TO_CAPTURE.reduce<UTMs>((params, key) => {
        let param: string | null;

        if (searchParams instanceof URLSearchParams) {
            param = searchParams.get(key);
        } else {
            param = searchParams[key] || null;
        }

        if (param) {
            return { ...params, [key]: param };
        }

        return params;
    }, {});
};

const persistToStorage = (utms: UTMs) => {
    const localStorageUtms = window.localStorage.getItem('cademyUtms');
    const localParams = localStorageUtms ? JSON.parse(localStorageUtms) : {};
    const referrer = localParams.referrer || utms?.referrer || document.referrer;
    const combined = {
        ...localParams,
        ...utms,
        referrer,
    };

    window.localStorage.setItem('cademyUtms', JSON.stringify(combined));
};

export const persistUtms = (data: Record<string, string>) => {
    if (typeof window === 'undefined') {
        return;
    }

    const filteredParams = filterParams(new URLSearchParams(data));
    persistToStorage(filteredParams);
};

export const useCaptureUtms = () => {
    useEffect(() => {
        try {
            if (typeof window === 'undefined') return;
            const urlSearchParams = new URLSearchParams(window.location.search);
            persistToStorage(filterParams(urlSearchParams));
        } catch (e) {
            console.error(e);
        }
    }, []);
};

export const getUtms = (): UTMs => {
    if (typeof window === 'undefined') return {};
    const localStorageUtms = window.localStorage.getItem('cademyUtms');
    const params = localStorageUtms ? JSON.parse(localStorageUtms) : {};
    const urlSearchParams = new URLSearchParams(params);
    return filterParams(urlSearchParams);
};

export const useGetUtms = () => {
    return useCallback(getUtms, []);
};
