"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Response {
    constructor(statusCode, body) {
        this.statusCode = statusCode;
        this.body = body;
        //
    }
}
exports.default = Response;
