'use client';
import { FunctionComponent, PropsWithChildren, createContext, useMemo } from 'react';

export type MarketplaceAppContext = {
    context: 'marketplace';
};

export type MinisiteAppContext = {
    context: 'minisite';
    educatorSlug: string;
};

export type EmbedAppContext = {
    context: 'embed';
};

export type AppContexts = MarketplaceAppContext | MinisiteAppContext | EmbedAppContext;

export const AppContext = createContext<AppContexts>({
    context: 'marketplace',
});

export const MarketplaceAppContext: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const appContext: MarketplaceAppContext = useMemo(() => {
        return {
            context: 'marketplace',
        };
    }, []);

    return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};

export const MinisiteAppContext: FunctionComponent<PropsWithChildren<{ educatorSlug: string }>> = ({
    educatorSlug,
    children,
}) => {
    const appContext: MinisiteAppContext = useMemo(() => {
        return {
            context: 'minisite',
            educatorSlug,
        };
    }, [educatorSlug]);

    return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};

export const EmbedAppContext: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const appContext: EmbedAppContext = useMemo(() => {
        return {
            context: 'embed',
        };
    }, []);

    return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};
